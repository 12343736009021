import { isNegativeAction, isPositiveAction } from '@/utils/credit.utils';

export default {
  methods: {
    currencyFormatter (params) {
      if (params !== undefined && params.value !== undefined) {
        return this.formatCurrency(params.value);
      }
      return '';
    },
    discountCurrencyFormatter (params) {
      if (params !== undefined && params.value !== undefined) {
        if (params.value > 0) {
          return '-' + this.formatCurrency(params.value);
        }
        return this.formatCurrency(params.value);
      } else if (params !== undefined) {
        if (params > 0) {
          return '-' + this.formatCurrency(params);
        }
        return this.formatCurrency(params);
      }
      return '';
    },
    formatCurrency (number) {
      return '\x24' + this.numberWithCommas(this.formatNumber(number));
    },
    formatNumber (number) {
      if (number !== undefined) {
        return (Math.round(number * 100) / 100).toFixed(2);
      }
      return '';
    },
    numberWithCommas (number) {
      if (number !== undefined) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      return '';
    },
    dateFormatter (params) {
      if (params !== undefined && params.value !== undefined) {
        const monthlist = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const date = new Date(params.value);
        return monthlist[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
      } else if (params !== undefined) {
        const monthlist = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const date = new Date(params);
        return monthlist[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
      }
      return '';
    },
    dateTimeFormatter (params) {
      if (params !== undefined && params.value !== undefined) {
        const monthlist = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const date = new Date(params.value);
        return monthlist[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear() + ' ' + date.toLocaleTimeString([], { timeStyle: 'short' });
      }
      return '';
    },
    orderIdFormatter (params) {
      if (params !== undefined) {
        let id;
        if (params.value !== undefined) {
          id = params.value.toUpperCase();
        } else {
          id = params.toUpperCase();
        }
        const sub1 = id.substring(12, 16);
        const sub2 = id.substring(16, 20);
        const sub3 = id.substring(20, 24);
        return sub1 + '-' + sub2 + '-' + sub3;
      }
      return '';
    },
    storeCreditCellStyle (cell) {
      if (isPositiveAction(cell.data?.action)) {
        return { color: 'green' };
      } else if (isNegativeAction(cell.data?.action)) {
        return { color: 'red' };
      } else {
        return null;
      }
    },
    creditBalanceCellStyle (balance) {
      if (balance > 0) {
        return { color: 'green' };
      } else if (balance < 0) {
        return { color: 'red' };
      } else {
        return null;
      }
    },
    storeCreditCellRenderer (cell) {
      if (!cell.node.group) {
        if (isPositiveAction(cell.data?.action)) {
          return this.formatCurrency(Number(cell.getValue()).toFixed(2));
        } else if (isNegativeAction(cell.data?.action)) {
          return `-${this.formatCurrency(Number(cell.getValue()).toFixed(2))}`;
        } else {
          return 'Error: Couldnt map action';
        }
      }
    }
  }
};
