<template>
  <v-container fluid>
    <v-row align-top justify="center">
      <v-col cols="12" md="8">
        <v-card color="text--black">
          <v-card-title>
            Frequently Asked Questions
          </v-card-title>
        <v-card-text>
          <v-list-item two-line v-for="(faq, index) in faqs" :key="index">
            <v-list-item-icon>
              <v-icon large>help</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="headline font-weight-bold faq-item"><span style="color: red"><b><u><i>{{faq.title}}</i></u></b></span></v-list-item-title>
              <v-list-item-title class="subtitle-1 faq-item"><div v-html="faq.body"></div></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'faq',
  components: {
  },
  data () {
    return {
      faqs: [
        {
          title: 'What is GizmoTrader Wholesale?',
          body: `GizmoTrader Wholesale is a leading wholesale supplier of Apple, Samsung and Google devices. We are proud to offer upfront pricing, consistent access to inventory, and a 30-day warranty on all orders.
<br><br>
Our goal is to eliminate the costly and frustrating friction involved in the wholesale purchasing of smartphones for resellers, distributors, repair shops and other small to medium-sized businesses.
<br><br>
As a wholesale company, we operate in a business-to-business (B2B) capacity only.  In order to purchase through our wholesale platform, documentation regarding your business may be required.  Your account will be verified prior to activation.
<br><br>
Our wholesale platform is designed to make it easy for smartphone resellers to purchase a small or large number of devices very quickly without the need for auctions, price lists or haggling back and forth.
<br><br>
Our strategy is to create systems that automate complex diagnostic, listing and fulfillment processes in order to remain efficient, eliminate the possibility for errors, ship quickly, and provide the best service possible to every wholesale customer.`
        },
        {
          title: 'How often do you add new products?',
          body: `We typically add new products each Monday through Friday around 11AM Eastern.
We also send out a daily email.  This “LISTING ALERT” includes a link to a file that
summarizes the products we added to the website that day (including all product
details and battery health).`
        },
        {
          title: 'Is there a way to know the Battery Health of ALL devices?',
          body: `
          Yes!  The Battery Health of our entire inventory is clearly listed on the website.  Additionally, all products are guaranteed to have at least 80% battery health (unless advertised otherwise).`
        },

        {
          title: 'Can you explain the Rating Scale and Real Photos?',
          body: `All  of the phones we sell have real photos of the device you will actually receive.
However, we also rate each device cosmetically.  All of our devices are Used or Refurbished in some way.
A brief summary of our rating scale is listed below.
<br><br>
It is important to note it is not possible for us to show every scratch on the phone and screen.
<b>So you should always use both the rating scale and photos in tandem when reviewing the condition of each device:</b>
<br><br>
A+ = Like New Condition. Little to no use.
<br><br>
A = Average to Excellent condition for a USED phone.
<br><br>
B = Pretty much everything else.  There will be scratches on the screen and/or body, below average.
<br><br>
C = Below average with serious cosmetic issue(s) (like deep/heavy scratches/dents or otherwise etc)`
        },
        {
          title: 'Can I shop on both my computer and mobile device?',
          body: `Our website can be accessed from ANY laptop or desktop computer. Additionally, a
mobile version of the site is accessible on most smartphones and tablets.  Both
versions of the site are fully functional and clearly list all items including REAL
photos.
<br><br>
Our desktop version offers a unique “grid style” layout that is most optimized for
wholesale purchasing.  For the best experience, we recommended primarily
accessing the site on a laptop or desktop computer using a Google Chrome browser.
<br><br>
If you experience any difficulties or have questions about how the site operates, please don’t hesitate to contact us on WhatsApp for support at +1-321-830-3841!`
        },
        {
          title: 'What forms of payment do you accept?',
          body: `PayPal is the default payment provider for new accounts.  If you do not have a PayPal account, you can also pay as a guest using a debit or credit card.  <b>If you choose to pay with PayPal, please keep in mind the corresponding payment fees will be added to your order total during the checkout process.</b>
<br><br>
Alternatively, if you wish to receive our <b>LOWEST CASH PRICE</b>, then please consider paying with <b>DIRECTPAY</b>, which includes Wire Payment, ACH Transfer or Zelle.
<br><br>
Most banks located in the USA and internationally are equipped to send wire payments on your behalf.  However, it may first be necessary to speak with your bank in order to gain access to this service.
<br><br>
Please contact us on WhatsApp at +1-321-830-3841 for more information or to activate the DIRECTPAY feature on your account.`
        },
        {
          title: 'What is DIRECTPAY and how do I activate the feature on my account?',
          body: `
          Our website has a feature called <b>“DIRECTPAY.”</b>   When activated on your account, you will see two checkout buttons in your cart (one for PayPal and another for DIRECTPAY).
          <br><br>

When you select DIRECTPAY, the website will automatically reserve the inventory and create an order without requiring you to pay immediately.  Then you will receive instructions to pay by Wire Transfer ACH Transfer, or Zelle.
<br><br>

Your store credit balance (both positive and negative) is managed automatically by our website and includes a detailed and exportable history of every order and payment.
<br><br>

This feature not only gives you access to our <b>LOWEST CASH PRICE</b>, but also provides you with the unique flexibility to reserve inventory at your convenience as soon as it is available.
<br><br>

Please contact us on WhatsApp at +1-321-830-3841 for more information or to activate this feature.


          `
        },

        {
          title: 'What is the “LOWEST CASH PRICE?”',
          body: `
          Our goal is to offer every customer the lowest possible price each day.  Therefore, the price published on our website is referred to as the <b>LOWEST CASH PRICE</b>.
          <br><br>
The <b>LOWEST CASH PRICE</b> is the price you will pay BEFORE any applicable payment fees have been added to your total.  <b>In order to receive the LOWEST CASH PRICE, you must pay with some form of DIRECT PAYMENT, which typically includes Wire Payment, ACH Transfer or Zelle. </b>
<br><br>
We also accept PayPal and have set this as the default payment method for new accounts.  <b>However, if you choose to pay with PayPal, please keep in mind the corresponding payment fees will be added to your order total during the checkout process. These charges are paid directly to the payment processor.</b>
<br><br>
If you’d like to receive our <b>LOWEST CASH PRICE</b>, please contact us on WhatsApp at +1-321-830-3841 in order to have the DIRECTPAY feature activated on your account.
          `
        },

        {
          title: 'Does the advertised price include accessories?',
          body: `In order to offer every customer the lowest possible price each day, we do not require buyers to pay for accessories they may not need.  The price you see on the website represents the purchase price of the advertised device only.


<br><br>
However, we do offer a full array of complementary accessories at extremely reasonable prices. Please visit the “Accessories” page on our website to review our selection of wall chargers, sync cables, generic retail boxes and MORE!


<br><br>
All accessories are advertised with real photos and listed at wholesale prices.

`
        },

        {
          title: 'Do you offer volume discounts?',
          body: `Our website is specially designed to eliminate a great deal of the friction typically involved in the wholesale purchasing process.  All customers on our wholesale platform are repeat buyers who purchase in large volume.

<br><br>
For these reasons, we advertise our products at the lowest possible price each day so that there is no need for auctions, price lists, or haggling back and forth.


<br><br>
We also adjust prices frequently, so if the products are not moving at an acceptable rate the price will typically come down quickly in an organic fashion.   We would encourage you to check back frequently if you’re looking for a specific price point.
`
        },
        {
          title: 'Do the products come with a Warranty?',
          body: `<b>Yes! We are proud to offer a 30 day Return Warranty* on all products. If you receive a product that is significantly not as described, please contact us within 30 days of purchase to receive a free DOMESTIC prepaid return label so that you can return the item. Unfortunately, we are not able to cover return shipping from international destinations. </b>
<br/><br/>
<b>All returns will be processed in the form of Store Credit, which can be fully tracked and redeemed on our website.</b>  At your request, we may issue a PayPal refund in lieu of Store Credit; however, any payment or ancillary fees will not be refunded.
<br/><br/>
*Warranty requests made outside 30 days of purchase may be charged a restocking fee or denied altogether. We make every effort to properly diagnose and certify each product using PhoneCheck diagnostic software with targeted human intervention. However, most of the products we sell are used or refurbished and can sometimes act unpredictably.
<br/><br/>
If you intend to make claims under this warranty, it is your responsibility to thoroughly check all products within the warranty period and to report any issues to us prior to re-selling the device. Failure to do so may equate to forfeiture of any expressed or implied warranty. The warranty does not cover products that become defective after they are shipped to you or products that are broken by an end-user.
<br/><br/>
Items shipped through freight forwarding companies are still covered by this warranty. However, any damage that may have occurred during the international shipping process will not be covered. This determination will be made at our sole discretion.
<br/><br/>
Freight forwarding companies are uninvolved third parties. We have no control over how the goods are re-packaged, handled, shipped, or received by these providers. If a product is shipped through a freight forwarding company, any international return shipping will be the responsibility of the buyer.
`
        },
        {
          title: 'What is GizmoTrader’s Shipping Policy?',
          body: `
<b><u>Shipping Methods:</u></b>
<br>
Depending on your location and order value, your shipments will be sent with online
tracking through a major carrier like FedEx, UPS, or USPS.
<br><br>
If you purchase a single device, your order will be shipped via USPS Ground
Advantage.
<br><br>
If you purchase two or more devices, your order will be shipped through FedEx 2Day
or FedEx Ground depending on your location.
<br><br>
We make every attempt to ensure all shipments are delivered safely and in a timely manner.  However, delivery estimates of the carriers are not guaranteed.


<br><br><b><u>Shipping Cost:</u></b>
<br>
We are proud to offer FREE SHIPPING when you spend at least $500.  If you spend
less than $500, there will be a flat $10 shipping fee.
<br><br>
<span style="color:red">
  It is important to note that the buyer assumes liability for all shipments once they leave our warehouse.  If you would like to purchase transit insurance for ANY or ALL of your shipments, please contact us on WhatsApp at +1-321-830-3841 for information about how to proceed.

</span>


<br><br><b><u>Shipping Cut-Off Time</u></b>
<br>
Our shipping cutoff time for PayPal orders is 8AM ET (Eastern Time).  Our shipping cutoff time for DIRECTPAY orders is 11AM ET.  If payment is received prior to this time, then your order will ship out the same business day.  If payment is received after this time, then it will be shipped the following business day.  Additional fees may apply for rush order processing or same day shipping after the cutoff time.


<br><br><b><u>What address will my orders ship to?</u></b>
<br>
<b>
  Orders paid by PayPal are shipped to the address you submit to PayPal
during the checkout process.
</b>
Your shipping label will be printed using the
shipping address attached to the PayPal payment for each order.  During the
checkout process, it is your responsibility to confirm the address you submit is
correct and that it also matches the address we have on file for you.

<br><br>
The address on file in your wholesale account is <b><u>ONLY</u></b> used for
determining your tax requirements and for DIRECTPAY orders.
<b><i>Orders paid by DIRECTPAY will be shipped to the address on file in your wholesale account.</i></b> It is your responsibility to confirm the address on your account is correct.
<br><br><b><u>Combined Orders:</u></b>
<br>
By default, ALL orders from the same customer to the same address will be
combined into a single shipment unless explicitly instructed otherwise.

<br><br><b><u>Shipping Terms (FOB Origin):</u></b>

<br>
Our Wholesale orders are shipped under <b>Free on Board (FOB) Origin</b> terms.
This means the buyer accepts title of the goods at the shipment origin point and
assumes all risk once the products leave our warehouse.
<b><i>
  Unless additional insurance is purchased, the buyer assumes liability if
  the goods are lost or damaged
</i></b>
or if the package is otherwise unable to be delivered by the shipping carrier.

<br><br>
While loss and damage with major shipping carriers and freight forwarding companies is rare, it is still a possibility from time to time.  However, transit insurance is often cost prohibitive.  For this reason, it is important that you understand your responsibility regarding these FOB Origin transit terms.  It is your responsibility to evaluate your risk tolerance and to make the best decision for your business. Our free and paid shipping services include only minimal declared value coverage unless both requested and paid for separately by the buyer.
<br><br>
<span style="color:red">
  If you would like to purchase transit insurance for ANY or ALL of your shipments, then please contact us directly on WhatsApp at +1-321-830-3841 for additional information about how to proceed.


</span>
`
        },
        {
          title: 'How can I contact GizmoTrader Wholesale?',
          body: `Please contact us at your convenience using the information below:
<br><br>
<b>WhatsApp:</b>
<br>
+1-321-830-3841
<br><br>
<b>Email:</b>
<br>
wholesale@gizmotrader.com
<br><br>
<b>Phone/Text:</b>
<br>
+1-321-830-3841
<br><br>
<b>Hours of Operation:</b>
<br>
Monday through Friday
<br>
9AM – 5PM
<br><br><br>
<i>
  By using this website, you accept and agree to be bound and abide by the content on this FAQs /
Terms page.  If you do not agree to these Terms, you must not access or use the Website.
</i>
`
        }
      ]

    };
  }
};
</script>

<style scoped>
.faq-item {
  white-space: normal;
}
</style>
