<template>
  <v-container fluid>
    <v-row>
      <v-col grow>
          <div id="checkout-direct-order-container" v-show="checkoutOrder !== null">
            <div id="timer-container">
              Due to rapidly changing inventory, please review the information below and complete the checkout process in the following time:
              <Timer id="checkout-timer" :isTimerRunning="isTimerRunning"></Timer>
            </div>

            <div id="order-info-direct-container">
              <v-data-table
                :headers="headers"
                :items="checkoutTableItems"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:[`body.append`]>
                  <tr v-if="showStoreCredit">
                    <td class="text-left">
                        Store Credit:
                    </td>
                    <td class="text-right">{{ formattedCredit }}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Order Total:</strong>
                    </td>
                    <td class="text-right">
                      <strong>{{ formattedOrderTotal }}</strong>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>

            <div id="checkout-direct-button-container-mobile" v-if="$vuetify.breakpoint.mobile">
              <span class="centered">
                <v-btn
                  color="green"
                  class="white--text"
                  large
                @click="onCheckoutDirect"
                >
                  I agree to send direct payment of<br>{{ formattedOrderTotal }} within 3 business days!
                </v-btn>
              </span>
            </div>

            <div>
              Our preferred payment method is <b>Zelle</b> as it is free and instant for all parties. However, we also accept payment by ACH or Wire Transfer.
            </div>

            <div style="margin: 10px 0px;">
              Please send your direct payment in the amount <b>{{ formattedOrderTotal }}</b> using the following information:
            </div>

            <div style="margin: 10px 20px;" v-if="$vuetify.breakpoint.mobile">
              <table style="font-size: 0.95em; border-collapse: collapse;">
                <tr>
                  <td style="width: 310px; border-bottom: 1px solid black; padding: 0; margin: 0;"><b>ZELLE PAYMENT INFO:</b></td>
                  <td style="width: 310px; border-bottom: 1px solid black; padding: 0; margin: 0;"></td>
                </tr>
                <tr>
                  <td>gizmotraderzelle@gmail.com</td>
                  <td></td>
                </tr>
                 <tr>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;"></td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;"><b>ACH/WIRE PAYMENT INFO:</b></td>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;"></td>
                </tr>
                <tr>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;">Receiving Bank Name:</td>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;">Chase</td>
                </tr>
                <tr>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;">Receiving Bank Address:</td>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;">315 S. Avalon Park Blvd., Orlando, FL 32828</td>
                </tr>
                <tr>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;">Beneficiary Name:</td>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;">GizmoTrader LLC</td>
                </tr>
                <tr>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;">Beneficiary Address:</td>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;">15236 E. Colonial Dr., Orlando, FL 32826</td>
                </tr>
                <tr>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;">Beneficiary Account #:</td>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;">707607690</td>
                </tr>
                <tr>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;">Beneficiary Routing #<br>(ACH Transfers):</td>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;">267084131</td>
                </tr>
                <tr>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;">Beneficiary Routing #<br>(Wire Transfers):</td>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;">021000021</td>
                </tr>
                <tr>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;">SWIFT Code<br>(International Wire Payments):</td>
                  <td style="border-bottom: 1px solid black; padding: 0; margin: 0;">CHASUS33</td>
                </tr>
              </table>
            </div>
            <div v-else style="margin: 10px 20px;">
              <table style="font-size: 0.95em;">
                <tr>
                  <td style="width: 260px"><b>ZELLE PAYMENT INFO:</b></td>
                  <td style="width: 310px"><b>ACH/WIRE PAYMENT INFO:</b></td>
                  <td style="width: 300px"></td>
                </tr>
                <tr>
                  <td>gizmotraderzelle@gmail.com</td>
                  <td>Receiving Bank Name:</td>
                  <td>Chase</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Receiving Bank Address:</td>
                  <td>315 S. Avalon Park Blvd., Orlando, FL 32828</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Beneficiary Name:</td>
                  <td>GizmoTrader LLC</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Beneficiary Address:</td>
                  <td>15236 E. Colonial Dr., Orlando, FL 32826</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Beneficiary Account #:</td>
                  <td>707607690</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Beneficiary Routing # (ACH Transfers):</td>
                  <td>267084131</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Beneficiary Routing # (Wire Transfers):</td>
                  <td>021000021</td>
                </tr>
                <tr>
                  <td></td>
                  <td>SWIFT Code (International Wire Payments):</td>
                  <td>CHASUS33</td>
                </tr>
              </table>
            </div>

            <div style="margin: 10px 0px 20px 0px;">
              <b>Important Note:</b> If proof of payment is not received within <b>3 business days</b>, this order may be canceled.
            </div>

            <div id="checkout-direct-button-container">
              <span class="centered">
                <v-btn
                  color="green"
                  class="white--text"
                  large
                @click="onCheckoutDirect"
                >
                  I agree to send direct payment of<br>{{ formattedOrderTotal }} within 3 business days!
                </v-btn>
              </span>
            </div>

            <div style="margin: 20px 0px;">
              <div>
                <b>Please contact us at any time for assistance via WhatsApp at <span style="white-space: nowrap;">(321) 830-3841</span>.</b>
                <h2 class="red--text">
                  Because this order is being with paid with Direct Payment, we will ship it to the address on file, which is listed below. Please contact us via WhatsApp at +1-321-830-3841 to make any updates.
                </h2>
                <p class="mb-0">{{ user.shippingAddress1 || user.address1 }}</p>
                <p v-show="user.shippingAddress2 || user.address2" class="mb-0">{{ user.shippingAddress2 || user.address2 }}</p>
                <p v-show="user.shippingAddress3 || user.address3" class="mb-0">{{ user.shippingAddress3 || user.address3}}</p>
                <p class="mb-0">{{ user.shippingCity || user.city }}, {{ user.shippingState || user.state}} {{ user.shippingZip || user.zip }}</p>
                <p class="mb-0">{{ (user.shippingCountry || user.country) ? countriesMap[user.shippingCountry || user.country].name : null }}</p>
              </div>
            </div>
          </div>
          <div v-show="checkoutOrder === null">
            No active orders in checkout process.
          </div>

          <v-dialog
            v-model="dialog.displayed"
            max-width="500"
          >
            <v-card>
              <v-card-title class="headline">{{ dialog.title }}</v-card-title>

              <v-card-text>
                {{ dialog.text }}
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="primary darken-1"
                  text
                  @click="onDialogClickHandler(false)"
                >
                  No
                </v-btn>

                <v-btn
                  color="primary darken-1"
                  text
                  @click="onDialogClickHandler(true)"
                >
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Timer from '@/components/checkout/Timer';
import { EventBus } from '@/_helpers';
import { router } from '@/router';
import formatterMixin from '@/_mixins/formatterMixin';
import countriesMixin from '@/_mixins/countriesMixin';

export default {
  name: 'checkout-direct',
  data () {
    return {
      drawer: true,
      isTimerRunning: true,
      useStoreCredit: true,
      headers: [
        {
          text: 'Description',
          align: 'left',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Amount',
          align: 'right',
          sortable: false,
          value: 'amount'
        }
      ],
      dialog: {
        displayed: false,
        title: '',
        text: '',
        next: null,
        suppress: false
      }
    };
  },
  components: {
    Timer
  },
  beforeMount () {

  },
  computed: {
    ...mapState({
      cartProducts: state => state.cart.products,
      cartCount: state => state.cart.count,
      cartTotal: state => state.cart.price,
      mediaBySku: state => state.products.mediaBySku,
      checkoutOrder: state => state.orders.checkoutOrder,
      confirmedOrder: state => state.orders.confirmedOrder,
      checkoutTimeSeconds: state => state.orders.checkoutTimeSeconds,
      balancesByUsername: state => state.credits.balancesByUsername,
      activeUserName: state => state.account.username,
      user: state => state.account.user
    }),
    storeCredit () {
      return this.balancesByUsername[this.activeUserName]?.balance || 0;
    },
    formattedCredit () {
      if (this.checkoutOrder === null) {
        return null;
      }

      if (this.storeCredit >= this.checkoutOrder.total) {
        return this.discountCurrencyFormatter(this.checkoutOrder.total);
      } else {
        return this.discountCurrencyFormatter(this.storeCredit);
      }
    },
    showStoreCredit () {
      return this.storeCredit > 0;
    },
    checkoutTableItems () {
      return [
        {
          name: 'Products Total:',
          amount: this.checkoutOrder === null ? '$0.00' : this.formatCurrency(this.checkoutOrder.itemtotal)
        },
        {
          name: 'Shipping & Handling:',
          amount: this.checkoutOrder === null ? '$0.00' : this.formatCurrency(this.checkoutOrder.shippingtotal)
        },
        {
          name: 'Sales Tax:',
          amount: this.checkoutOrder === null ? '$0.00' : this.formatCurrency(this.checkoutOrder.taxtotal)
        }
      ];
    },
    orderTotal () {
      let total = 0;

      if (this.checkoutOrder !== null) {
        total = this.checkoutOrder.total;
        if (this.storeCredit > 0 && this.useStoreCredit) {
          // if they have more store credit than the order total, then show 0
          if (this.storeCredit >= total) {
            total = 0;
          } else {
            total -= this.storeCredit;
          }
        }
      }

      return total;
    },
    formattedOrderTotal () {
      return this.formatCurrency(this.orderTotal);
    }
  },
  created () {

  },
  mounted () {
    this.dialog.suppress = false;
    this.isTimerRunning = true;

    // Listen to the event.
    EventBus.$on('checkout-timed-out', this.checkoutTimedOutHandler);
  },
  updated () {

  },
  methods: {
    ...mapActions('cart', ['clearCartProducts', 'removeAllCartProducts', 'validate', 'synchronize']),
    ...mapActions('orders', ['verifyOrderDirect', 'cancelCheckoutOrder']),
    ...mapActions('credits', ['getCreditByUsername']),
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    }),
    stopCheckoutTimer () {
      this.isTimerRunning = false;
      this.emitGlobalStopCheckoutTimerEvent();
    },
    onDialogClickHandler (confirm) {
      this.dialog.displayed = false;
      if (confirm) {
        this.stopCheckoutTimer();

        // CANCEL CHECKOUT ORDER
        // Get Checkout Order Status
        if (this.checkoutOrder !== null) {
          const fulfillmentStatus = this.checkoutOrder.fulfillmentStatus.replace(/_/g, '-').toLowerCase();
          this.cancelCheckoutOrder(fulfillmentStatus);
        }

        // NAVIGATE TO NEXT ROUTE
        this.dialog.next();
      } else {
        // STAY ON CHECKOUT PAGE!
        this.dialog.next(false);
        this.dialog.next = null;
      }
    },
    onCheckoutDirect () {
      // Stop checkout timer
      this.stopCheckoutTimer();

      this.loadingOverlay(true);

      const orderParams = {
        useStoreCredit: this.storeCredit > 0, // if store credit > 0, always use it
        isDirectPayment: true
      };

      // VERIFY THIS ORDER ON THE SERVER
      this.verifyOrderDirect(orderParams).then(() => {
        this.removeAllCartProducts();
        setTimeout(() => {
          this.getCreditByUsername(this.activeUserName);
        }, 500);
        this.dialog.suppress = true;

        // this.loadingOverlay(false);
        router.push('/confirmation-direct');
      });
    },
    checkoutTimedOutHandler () {
      this.cancelCheckoutOrder('pending-payment').then(() => {
        // this.errorAlert('Checkout canceled due to timeout');
        this.dialog.suppress = true;
        // router.push('/cart');
      }).catch((err) => {
        // this.lockScreenOverlay({ displayLockScreen: true, type: 'info', message: 'This order is out of sync with the server. Please refresh browser!' });
        // this.errorAlert('Checkout canceled due to timeout');
        this.dialog.suppress = true;
        // router.push('/cart');
      });
    },
    emitGlobalStopCheckoutTimerEvent () {
      EventBus.$emit('stop-checkout-timer', {});
    }
  },
  beforeRouteLeave (to, from, next) {
    // check if we have an active checkout order
    if (this.checkoutOrder !== null) {
      if (this.dialog.suppress) {
        // Dialog suppressed, proceed to next route
        this.emitGlobalStopCheckoutTimerEvent();
        next();
      } else {
        this.dialog.title = 'Cancel Checkout?';
        this.dialog.text = 'Are you sure you want to cancel the current checkout process?';
        this.dialog.next = next;

        this.dialog.displayed = true;
      }
    } else {
      next();
    }
  },
  watch: {
  },
  mixins: [
    formatterMixin,
    countriesMixin
  ]
};

</script>

<style scoped>

/* #paypal-button-container {
  margin-top: 20px;
  width: 280px;
} */

#timer-container {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.2em;
}

#checkout-timer {
  font-size: 2em;
  position: relative;
  top: 6px;
  left: 14px;
}

#order-info-direct-container {
  width: 320px;
  margin-top: 20px;
  margin-bottom: 20px;
}

#checkout-direct-button-container {
  margin-top: 12px;
}

#checkout-direct-button-container-mobile {
  margin-top: 12px;
  margin-bottom: 20px;
}

.v-btn {
  height: 60px;
}

.v-card__text, .v-card__title {
  word-break: normal !important;
}

</style>
