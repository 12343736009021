import Vue from 'vue';
import App from './components/app/App.vue';
import { router } from './router';
import { store } from './store';
import vuetify from './plugins/vuetify';
import { LicenseManager } from 'ag-grid-enterprise';
import VeeValidate from 'vee-validate';
import Socket from './plugins/socket';

Vue.config.productionTip = false;
Vue.use(VeeValidate);
Vue.use(Socket);

// Set ag-grid enterprise license key
LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-060285}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Gizmotrader.com}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{gizmotrader.wholesale}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{gizmotrader.wholesale}_need_to_be_licensed___{gizmotrader.wholesale}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{24_July_2025}____[v3]_[01]_MTc1MzMxMTYwMDAwMA==8e7a7ee2427a54ce4caef741e1ada210');

window.vm = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
