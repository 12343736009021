<template>
  <v-container fluid>
    <v-row>
        <v-col grow>
          <div id="checkout-order-container" v-show="checkoutOrder !== null">

            <div id="timer-container">
              Due to rapidly changing inventory, please complete the checkout process in the following time:
              <Timer id="checkout-timer" :isTimerRunning="isTimerRunning"></Timer>
            </div>

            <v-row>
              <v-col>
                <div id="order-info-container">
                  <v-data-table
                    :headers="headers"
                    :items="checkoutTableItems"
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template v-slot:items="props">
                        <tr>
                          <td>{{ props.item.name }}</td>
                          <td class="text-right">{{ props.item.amount }}</td>
                        </tr>
                    </template>
                    <template v-slot:[`body.append`]>
                      <tr v-if="showStoreCredit">
                        <td class="text-left">
                          <v-checkbox
                            v-model="useStoreCredit"
                            label="Store Credit"
                          />
                        </td>
                        <td class="text-right">{{ formattedCredit }}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Order Total:</strong>
                        </td>
                        <td class="text-right">
                          <strong>{{ formattedOrderTotal }}</strong>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>

                <div v-show="orderTotal > 0" id="paypal-button-container" class="checkout-button-container"></div>
                <v-btn
                    v-show="orderTotal <= 0"
                    color="green"
                    class="white--text checkout-button-container"
                    large
                    @click="onCheckoutWithoutPaypal"
                    >
                      Checkout
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-show="orderTotal <= 0">
                <h2 class="red--text">Because this order is being paid with Store Credit, we will ship it to the address on file, which is listed below. Please contact us via WhatsApp at +1-321-830-3841 to make any updates.</h2>
                <p class="mb-0">{{ user.shippingAddress1 || user.address1 }}</p>
                <p v-show="user.shippingAddress2 || user.address2" class="mb-0">{{ user.shippingAddress2 || user.address2 }}</p>
                <p v-show="user.shippingAddress3 || user.address3" class="mb-0">{{ user.shippingAddress3 || user.address3}}</p>
                <p class="mb-0">{{ user.shippingCity || user.city }}, {{ user.shippingState || user.state}} {{ user.shippingZip || user.zip }}</p>
                <p class="mb-0">{{ (user.shippingCountry || user.country) ? countriesMap[user.shippingCountry || user.country].name : null }}</p>
              </v-col>
              <v-col v-show="orderTotal > 0">
                <h2 class="red--text">Because this order is being paid with PayPal, we must ship it to the address you provide to PayPal during the checkout process. It is your responsibility to confirm the address you provide to PayPal is correct.  Please contact us via WhatsApp at +1-321-830-3841 to make any updates.</h2>
              </v-col>
            </v-row>
          </div>

          <div v-show="checkoutOrder === null">
            No active orders in checkout process.
          </div>

          <v-dialog
            v-model="dialog.displayed"
            max-width="500"
          >
            <v-card>
              <v-card-title class="headline">{{ dialog.title }}</v-card-title>

              <v-card-text>
                {{ dialog.text }}
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="primary darken-1"
                  text
                  @click="onDialogClickHandler(false)"
                >
                  No
                </v-btn>

                <v-btn
                  color="primary darken-1"
                  text
                  @click="onDialogClickHandler(true)"
                >
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Timer from '@/components/checkout/Timer';
import { EventBus } from '@/_helpers';
import { router } from '@/router';
import formatterMixin from '@/_mixins/formatterMixin';
import countriesMixin from '@/_mixins/countriesMixin';

export default {
  name: 'checkout',
  data () {
    return {
      drawer: true,
      isTimerRunning: true,
      useStoreCredit: false,
      headers: [
        {
          text: 'Description',
          align: 'left',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Amount',
          align: 'right',
          sortable: false,
          value: 'amount'
        }
      ],
      dialog: {
        displayed: false,
        title: '',
        text: '',
        next: null,
        suppress: false
      }
    };
  },
  components: {
    Timer
  },
  beforeMount () {

  },
  computed: {
    ...mapState({
      cartProducts: state => state.cart.products,
      cartCount: state => state.cart.count,
      cartTotal: state => state.cart.price,
      mediaBySku: state => state.products.mediaBySku,
      checkoutOrder: state => state.orders.checkoutOrder,
      confirmedOrder: state => state.orders.confirmedOrder,
      checkoutTimeSeconds: state => state.orders.checkoutTimeSeconds,
      balancesByUsername: state => state.credits.balancesByUsername,
      activeUserName: state => state.account.username,
      user: state => state.account.user
    }),
    storeCredit () {
      return this.balancesByUsername[this.activeUserName]?.balance || 0;
    },
    formattedCredit () {
      if (this.checkoutOrder === null) {
        return null;
      }

      let credit = 0;

      // We already processed the order on the server. Just display store credit used
      if ('storecreditused' in this.checkoutOrder && this.checkoutOrder.storecreditused > 0) {
        credit = this.checkoutOrder.storecreditused;
      } else {
        if (this.storeCredit >= this.checkoutOrder.total) {
          credit = this.checkoutOrder.total;
        } else {
          credit = this.storeCredit;
        }
      }

      return this.discountCurrencyFormatter(credit);
    },
    showStoreCredit () {
      return this.storeCredit > 0;
    },
    checkoutTableItems () {
      return [
        {
          name: 'Products Total:',
          amount: this.checkoutOrder === null ? '$0.00' : this.formatCurrency(this.checkoutOrder.itemtotal)
        },
        {
          name: 'Shipping & Handling:',
          amount: this.checkoutOrder === null ? '$0.00' : this.formatCurrency(this.checkoutOrder.shippingtotal)
        },
        {
          name: 'Sales Tax:',
          amount: this.checkoutOrder === null ? '$0.00' : this.formatCurrency(this.checkoutOrder.taxtotal)
        },
        {
          name: `Payment Fee:${this.paypalFeePercentText}`,
          amount: this.formatCurrency(this.paypalFee)
        }
      ];
    },
    paypalFeePercentText () {
      return this.checkoutOrder ? ` (${this.checkoutOrder.fee}%)` : '';
    },
    paypalFee () {
      if (this.checkoutOrder === null) {
        return 0.00;
      }

      if (this.orderTotalAfterCredit > 0) {
        // If they have enough store credit to cover the whole order then
        // they will not be using paypal and dont get charged a fee

        if (this.checkoutOrder.feeTotal > 0) {
          return this.checkoutOrder.feeTotal;
        } else {
          return this.orderTotalAfterCredit * this.checkoutOrder.fee / 100.0;
        }
      } else {
        return 0.00;
      }
    },
    ...mapGetters('account', ['userIsReadOnly']),
    formattedCartCount () {
      return this.numberWithCommas(this.cartCount);
    },
    formattedCartPrice () {
      return this.formatCurrency(this.cartTotal);
    },
    orderTotalAfterCredit () {
      let total = 0;

      if (this.checkoutOrder !== null) {
        total = this.checkoutOrder.total;
        // if we have store credit and we dont have storecreditused. if usedstorecredit is 0
        // then that means we havent calculated the order total with store credit yet
        if (this.storeCredit > 0 && this.useStoreCredit && this.checkoutOrder.tempstorecreditused === 0) {
          // if they have more store credit than the order total, then show 0
          if (this.storeCredit >= total) {
            total = 0;
          } else {
            // Remove pre-store credit fee and add new fee factoring store credit in
            // total = total - this.checkoutOrder.fee + this.paypalFee;

            total -= this.storeCredit;
          }
        }
      }

      return total;
    },
    orderTotal () {
      // if we have a fee total, then we already process the order on the backend and order.total was updated
      // otherwise, order.total is still a subtotal so we need to add the fee
      if (this.checkoutOrder && this.checkoutOrder.feeTotal > 0) {
        return this.orderTotalAfterCredit;
      } else {
        return this.orderTotalAfterCredit + this.paypalFee;
      }
    },
    formattedOrderTotal () {
      return this.formatCurrency(this.orderTotal);
    }
  },
  created () {

  },
  mounted () {
    this.dialog.suppress = false;
    this.isTimerRunning = true;

    // Listen to the event.
    EventBus.$on('checkout-timed-out', this.checkoutTimedOutHandler);

    // Render the PayPal button into #paypal-button-container
    paypal.Buttons({

      // Something went wrong!
      onError: (err) => {
        // Show an error page here, when an error occurs
        console.log(err);

        // Order might not be in PENDING_PAYPAL_CHECKOUT status yet, depending on where it failed.
        if (this.checkoutOrder !== null) {
          this.cancelPendingCheckoutOrder();
        }

        this.dialog.suppress = true;
        this.errorAlert('Error during checkout! Please try again or contact support.');
        router.push('/cart');
      },

      // Checkout canceled
      onCancel: (data) => {
        this.loadingOverlay(true);

        if (this.checkoutOrder !== null) {
          this.cancelCheckoutOrder('pending-paypal-checkout');
        }

        // Show a cancel page, or return to cart
        // console.log(data);

        this.dialog.suppress = true;

        // Force vuex store to refresh products with server cart database
        this.refreshCartProducts();
        this.refreshOrders();

        // Fetch PENDING_PAYMENT || PENDING_PAYPAL_CHECKOUT order to see if this user is currently
        // in the checkout process from a different browsing session.
        this.loadCheckoutOrder();

        router.push('/cart');
      },

      // Set up the transaction
      createOrder: (data, actions) => {
        // Stop checkout timer
        this.stopCheckoutTimer();

        // NEW: SETUP PAYPAL TRANSACTION ON SERVER INSTEAD OF CLIENT!!
        return this.createPayPalTransaction({
          orderId: this.checkoutOrder.id,
          useStoreCredit: this.useStoreCredit && this.storeCredit > 0
        });
      },

      // Finalize the transaction
      onApprove: (data, actions) => {
        this.loadingOverlay(true);

        const orderParams = {
          orderId: this.checkoutOrder.id,
          paypalOrderId: data.orderID
        };

        // NEW: CAPTURE PAYPAL TRANSACTION ON SERVER INSTEAD OF CLIENT!!
        return this.capturePayPalTransaction(orderParams).then((order) => {
          this.loadVerifiedOrder(order);
          this.removeAllCartProducts();
          setTimeout(() => {
            this.getCreditByUsername(this.user.username);
          }, 500);

          this.dialog.suppress = true;
          router.push('/confirmation');
        });
      }

    }).render('#paypal-button-container');

    if (this.checkoutOrder !== null) {
      this.loadOrderInformation();
    }
  },
  updated () {

  },
  methods: {
    ...mapActions('cart', ['clearCartProducts', 'removeAllCartProducts', 'refreshCartProducts', 'validate', 'synchronize']),
    ...mapActions('orders', [
      'verifyOrder',
      'updateOrderStatus',
      'cancelCheckoutOrder',
      'cancelPendingCheckoutOrder',
      'createPayPalTransaction',
      'capturePayPalTransaction',
      'loadVerifiedOrder',
      'refreshOrders',
      'loadCheckoutOrder',
      'verifyOrderDirect'
    ]),
    ...mapActions('credits', ['getCreditByUsername']),
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading',
      lockScreenOverlay: 'alert/lockScreen'
    }),
    stopCheckoutTimer () {
      this.isTimerRunning = false;
      this.emitGlobalStopCheckoutTimerEvent();
    },
    getPayPalItems () {
      // Convert Wholesale Products objects to PayPal Items objects
      // https://developer.paypal.com/docs/api/orders/v2/#definition-item
      const checkoutItems = this.checkoutOrder.items;
      const paypalItems = [];

      checkoutItems.forEach(checkoutItem => {
        let paypalItem = null;
        // if (checkoutItem.sku.toLowerCase().includes("acc ")) {
        if (checkoutItem.sku.toLowerCase().substring(0, 3) === 'acc') {
          paypalItem = {
            name: checkoutItem.genericdescription, // The item name or title.
            unit_amount: { // The item price or rate per unit. If you specify unit_amount, purchase_units[].amount.breakdown.item_total is required. Must equal unit_amount * quantity for all items.
              currency_code: 'USD',
              value: checkoutItem.wholesaleprice
            },
            // tax: { // The item tax for each unit. If tax is specified, purchase_units[].amount.breakdown.tax_total is required. Must equal tax * quantity for all items.
            //     currency_code: "USD",
            //     value: 10.00
            // },
            quantity: checkoutItem.quantity, // The item quantity. Must be a whole number.
            sku: checkoutItem.sku // The stock keeping unit (SKU) for the item.
          };
        } else {
          paypalItem = {
            name: checkoutItem.title, // The item name or title.
            unit_amount: { // The item price or rate per unit. If you specify unit_amount, purchase_units[].amount.breakdown.item_total is required. Must equal unit_amount * quantity for all items.
              currency_code: 'USD',
              value: checkoutItem.wholesaleprice
            },
            // tax: { // The item tax for each unit. If tax is specified, purchase_units[].amount.breakdown.tax_total is required. Must equal tax * quantity for all items.
            //     currency_code: "USD",
            //     value: 10.00
            // },
            quantity: '1', // The item quantity. Must be a whole number.
            sku: checkoutItem.sku // The stock keeping unit (SKU) for the item.
          };
        }

        if (paypalItem !== null) {
          paypalItems.push(paypalItem);
        }
      });
      return paypalItems;
    },
    onDialogClickHandler (confirm) {
      this.dialog.displayed = false;
      if (confirm) {
        this.stopCheckoutTimer();

        // CANCEL CHECKOUT ORDER
        // Get Checkout Order Status
        if (this.checkoutOrder !== null) {
          const fulfillmentStatus = this.checkoutOrder.fulfillmentStatus.replace(/_/g, '-').toLowerCase();
          this.cancelCheckoutOrder(fulfillmentStatus);
        }

        // NAVIGATE TO NEXT ROUTE
        this.dialog.next();
      } else {
        // STAY ON CHECKOUT PAGE!
        this.dialog.next(false);
        this.dialog.next = null;
      }
    },
    loadOrderInformation () {
      if (this.checkoutOrder.fulfillmentStatus.toUpperCase() === 'PENDING_PAYPAL_CHECKOUT') {
        this.stopCheckoutTimer();
      }
    },
    checkoutTimedOutHandler () {
      // this.stopCheckoutTimer();
      this.cancelCheckoutOrder('pending-payment').then(() => {
        // this.errorAlert('Checkout canceled due to timeout');
        this.dialog.suppress = true;
        // router.push('/cart');
      }).catch((err) => {
        // this.lockScreenOverlay({ displayLockScreen: true, type: 'info', message: 'This order is out of sync with the server. Please refresh browser!' });
        // this.errorAlert('Checkout canceled due to timeout');
        this.dialog.suppress = true;
        // router.push('/cart');
      });
    },
    emitGlobalStopCheckoutTimerEvent () {
      EventBus.$emit('stop-checkout-timer', {});
    },
    onCheckoutWithoutPaypal () {
      // Stop checkout timer
      this.stopCheckoutTimer();

      this.loadingOverlay(true);

      const orderParams = {
        useStoreCredit: this.storeCredit > 0, // if store credit > 0, always use it
        isDirectPayment: false
      };

      // VERIFY THIS ORDER ON THE SERVER
      this.verifyOrderDirect(orderParams).then(() => {
        this.removeAllCartProducts();

        setTimeout(() => {
          this.getCreditByUsername(this.user.username);
        }, 500);

        this.dialog.suppress = true;

        // this.loadingOverlay(false);
        router.push('/confirmation');
      });
    }
  },
  beforeRouteLeave (to, from, next) {
    // check if we have an active checkout order
    if (this.checkoutOrder !== null) {
      if (this.dialog.suppress) {
        // Dialog suppressed, proceed to next route
        this.emitGlobalStopCheckoutTimerEvent();
        next();
      } else {
        this.dialog.title = 'Cancel Checkout?';
        this.dialog.text = 'Are you sure you want to cancel the current checkout process?';
        this.dialog.next = next;

        this.dialog.displayed = true;
      }
    } else {
      next();
    }
  },
  watch: {
    checkoutOrder (to, from) {
      this.loadOrderInformation();

      console.log(this.checkoutOrder, 'checkout order');
      if (this.storeCredit > 0 && this.checkoutOrder.fulfillmentStatus === 'PENDING_PAYMENT') {
        this.useStoreCredit = true;
      }
    }
  },
  mixins: [
    formatterMixin,
    countriesMixin
  ]
};

</script>

<style scoped>

.checkout-button-container {
  margin-top: 20px;
  width: 320px;
}

#timer-container {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.2em;
}

#checkout-timer {
  font-size: 2em;
  position: relative;
  top: 6px;
  left: 14px;
}

#order-info-container {
  width: 320px;
}

.v-card__text, .v-card__title {
  word-break: normal !important;
}

</style>
