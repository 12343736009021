import Vue from 'vue';
import { creditsService } from '@/_services';

const state = {
  balancesByUsername: {},
  allCreditsByUsername: {}
};

const actions = {
  getCreditByUsername ({ commit }, username) {
    return new Promise((resolve, reject) => {
      creditsService.getCreditBalanceByUserName(username).then(
        (response) => {
          // http success, call the mutator and change something in state
          commit('setCreditByUsername', { credit: response.credit });
          resolve(response); // Let the calling function know that http is done. You may send some data back
        },
        (error) => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        }
      );
    });
  },
  createCredit ({ commit }, credit) {
    return new Promise((resolve, reject) => {
      creditsService.createCredit(credit).then(
        (response) => {
          // http success, call the mutator and change something in state
          commit('setCreditByUsername', { credit: response.credit });
          commit('addCredit', { credit: response.credit });
          resolve(response); // Let the calling function know that http is done. You may send some data back
        },
        (error) => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        }
      );
    });
  },
  getBalances ({ commit }) {
    return new Promise((resolve, reject) => {
      creditsService.getBalances().then(
        (response) => {
          // http success, call the mutator and change something in state
          commit('setBalances', { balances: response.balances });
          resolve(response); // Let the calling function know that http is done. You may send some data back
        },
        (error) => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        }
      );
    });
  },
  getAllCredits: async function getAllCredits ({ commit }) {
    // let allCredits = [];

    let page = 1;

    while (page != null) {
      const response = await creditsService.getAllCredits(page);

      const creditsByUsername = response.creditsByUsername;

      // todo fix this
      for (const user in creditsByUsername) {
        const creditsById = creditsByUsername[user];
        commit('addCreditsByUsername', { credits: creditsById, username: user });
      }

      page = response.nextPage;
    }

    return new Promise((resolve, reject) => {
      // http success, call the mutator and change something in state
      resolve(state.allCreditsByUsername); // Let the calling function know that http is done. You may send some data back
    });
  },
  getAllCreditsByUsername: async function getAllCredits ({ commit }, username) {
    const creditsById = await creditsService.getAllCreditsByUsername(username);

    commit('addCreditsByUsername', { credits: creditsById, username: username });

    return new Promise((resolve, reject) => {
      // http success, call the mutator and change something in state
      resolve(state.allCreditsByUsername); // Let the calling function know that http is done. You may send some data back
    });
  }
};

const mutations = {
  setCreditByUsername (state, { credit }) {
    Vue.set(state.balancesByUsername, credit.username, credit);
  },
  setBalances (state, { balances }) {
    state.balancesByUsername = balances;
  },
  addCreditsByUsername (state, { credits, username }) {
    const existingCredits = username in state.allCreditsByUsername ? state.allCreditsByUsername[username] : {};
    Vue.set(state.allCreditsByUsername, username, { ...existingCredits, ...credits });
  },
  addCredit (state, { credit }) {
    state.allCreditsByUsername[credit.username][credit.id] = credit;
  }
};

const getters = {
  allCreditsArray (state) {
    return Object.values(state.allCreditsByUsername).flatMap(userCredits =>
      Object.values(userCredits)
    );
  }
};

export const credits = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
