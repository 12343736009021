<template>
  <v-container fluid id="cart-page">
    <v-dialog
      v-model="dialog.displayed"
      max-width="500"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>

        <v-card-text>
          {{ dialog.text }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary darken-1"
            text
            @click="onDialogClickHandler(false)"
          >
            No
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="onDialogClickHandler(true)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Direct Payment Dialog -->
    <v-dialog
      v-model="directPaymentDialog.displayed"
      width="800px"
      :fullscreen="$vuetify.breakpoint.mobile">

      <v-card>
        <v-card-text>
          <div class="centered">
            <h1>
              <!-- {{ directPaymentDialog.title }} -->
              Unable to create new Direct Payment Order
            </h1>
          </div>
          <!-- {{ directPaymentDialog.text }} -->
          <div class="direct-payment-message">
            <p>The maximum limit for pending direct payment orders at a single time is {{ formattedDirectPaymentMaxLimit }}. {{ directPaymentDialog.text }}</p>
            <p>Please complete this order using PayPal Checkout or remove some products from your cart or wait until existing direct payment orders are complete and try again.</p>
            <p>For assistance, please contact us on WhatsApp at <span style="white-space: nowrap;"><b>+1-321-830-3841 </b></span> or email us at <a href="mailto:wholesale@gizmotrader.com">wholesale@gizmotrader.com</a>.</p>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-row align-center>
            <v-btn color="primary" text @click="onCloseDirectPaymentDialogHandler">Close</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- Invalid Products Dialog -->
    <v-dialog
      v-model="invalidProductsDialog.displayed"
      max-width="800"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title class="headline">{{ invalidProductsDialog.title }}</v-card-title>

        <v-card-text>
            <v-list two-line>
            <template v-for="(item) in invalidProductsDialog.items">
              <v-list-item
                :key="item.title"
              >
                <v-list-item-content>
                  <v-list-item-title v-html="item.title"></v-list-item-title>
                  <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary darken-1"
            text
            @click="onInvalidProductsDialogClickHandler(false)"
          >
            Cancel
          </v-btn>

          <v-btn
            color="primary darken-1"
            @click="onInvalidProductsDialogClickHandler(true)"
          >
            Accept Cart Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="mediaDialog"
        width="1000px"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <v-card>
          <v-card-actions height="style: 90px">
            <v-container class="media-product-header" fill-height>
              <v-row row align-center>
                <v-col cols="12">
                  <span class="centered">
                    <h1>
                      {{ mediaProductTitle }} <span class="media-product-price">{{ mediaProductPrice }}</span>
                      <span class="media-cart-button ">
                        <v-btn
                          large
                          color="pink"
                          class="white--text"
                          @click="onRemoveFromCartFromMedia"
                        >Remove From Cart</v-btn>
                      </span>
                    </h1>
                  </span>
                </v-col>
              </v-row>
            </v-container>

          </v-card-actions>
          <v-carousel
            :cycle="cycle"
            v-model="slider"
            height="667px"
            >
            <v-carousel-item
              v-for="(item,i) in items"
              :key="i"
              :src="item.src"
              :transition="cycle"
              :reverse-transition="cycle"
            ></v-carousel-item>
          </v-carousel>
          <v-card-actions class="pa-0">
            <v-container>
              <v-row>
                <v-col cols="12" align="center">
                  <v-btn color="primary" text @click="onCloseMediaDialogHandler">Close</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog
      v-model="functionalityDialog"
      width="1200px"
      :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-actions>
          <v-container class="media-product-header" fill-height>
            <v-row>
              <v-col cols="12">
                <span class="centered">
                  <h1>
                    {{ mediaProductTitle }} <span class="media-product-price">{{ mediaProductPrice }}</span>
                    <span class="media-cart-button">
                      <v-btn
                        large
                        color="pink"
                        class="white--text"
                        @click="onRemoveFromCartFromFunctionality"
                      >Remove From Cart</v-btn>
                    </span>
                    </h1>
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>

        <v-container>
          <v-row row align-center>
            <v-col cols="1"></v-col>
            <v-col cols="10">
              <span class="centered">
                {{ mediaProductNotes }}
              </span>
            </v-col>
            <v-col cols="1"></v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-container>
            <v-row>
              <v-col cols="12" align="center">
                <v-btn color="primary" text @click="onCloseFunctionalityDialogHandler">Close</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div id="mobileControls" class="px-3 mt-2" v-if="$vuetify.breakpoint.mobile">
      <v-row>
        <v-text-field
          class="px-3"
          label="Search Cart"
          v-model="searchFilter"
          outlined
          clearable
          dense
        ></v-text-field>
      </v-row>
      <v-row class="mt-0 mb-3 px-3">
        <v-btn
          v-if="payPalEnabled && !userIsReadOnly"
          id="checkout-button"
          :disabled="cartCount === 0"
          color="pink"
          class="white--text centered mr-3"
        @click="onCheckout"
        >
          PayPal
        </v-btn>
        <v-btn
          v-if="directPaymentEnabled === true && !userIsReadOnly"
          id="checkout-direct-button"
          :disabled="cartCount === 0"
          color="green"
          class="white--text centered"
        @click="onCheckoutDirect"
        >
          Direct
        </v-btn>
      </v-row>
      <v-row  class="justify-start mb-3">
        <v-btn
          id="remove-from-cart-button"
          :disabled="countSelected === 0"
          color="pink"
          class="white--text centered mx-3"
          @click="onRemoveFromCart"
        >
          Remove
        </v-btn>
        <v-btn
          :disabled="countSelected === 0"
          outlined
          @click="onClearSelection"
          class="clear-cart-selection-button centered">
            Clear
        </v-btn>
        <v-btn
          id="refresh-cart-btn"
          class="mx-3"
          fab
          dark
          small
          color="pink"
          @click="refreshCartProducts">
            <v-icon dark>refresh</v-icon>
        </v-btn>
      </v-row>
      <v-row v-if="rowData && rowData.length > 0">
        <v-spacer></v-spacer>
        Showing {{rowData.length}} products.&nbsp;<a href="#" @click="onSelectAll()">Select All&nbsp;({{filteredProductsQuantity}})</a>
        <v-spacer></v-spacer>
      </v-row>
    </div>
    <div v-else id="desktopControls">
      <v-row class="justify-start">
        <v-col cols="6">
          <v-text-field
            label="Search Cart"
            v-model="searchFilter"
            outlined
            clearable
          ></v-text-field>
        </v-col>
        <v-btn
          v-if="payPalEnabled && !userIsReadOnly"
          id="checkout-button"
          :disabled="cartCount === 0"
          color="pink"
          class="white--text centered mt-3 mr-3"
        @click="onCheckout"
        >
          PayPal Checkout
        </v-btn>

        <!-- Button with dynamic disabled state and conditional tooltip -->
        <v-tooltip v-if="cartCount === 0 || directPaymentEnabled === false || userIsReadOnly" bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" style="height: fit-content;">
              <v-btn
                id="checkout-direct-button"
                disabled
                color="green"
                class="white--text centered mt-3"
              >
                Direct Payment
              </v-btn>
            </div>
          </template>
          <span>PAY DIRECT TO SAVE ON PAYMENT FEES. CONTACT US ON WHATSAPP AT +1-321-830-3841 FOR DETAILS.</span>
        </v-tooltip>

        <v-btn
          v-else
          id="checkout-direct-button"
          color="green"
          class="white--text centered mt-3"
          @click="onCheckoutDirect"
        >
          Direct Payment
        </v-btn>

      </v-row>
      <v-row  class="justify-start mb-3">
        <v-btn
          id="remove-from-cart-button"
          :disabled="countSelected === 0"
          color="pink"
          class="white--text centered mx-3"
          @click="onRemoveFromCart"
        >
          Remove From Cart
        </v-btn>
        <v-btn
          :disabled="countSelected === 0"
          outlined
          @click="onClearSelection"
          class="clear-cart-selection-button centered">
            Clear Selection
        </v-btn>
        <v-btn
          id="refresh-cart-btn"
          class="mx-3"
          fab
          dark
          small
          color="pink"
          @click="refreshCartProducts">
            <v-icon dark>refresh</v-icon>
        </v-btn>
      </v-row>
    </div>
    <v-row>
      <v-col cols="12" class="pt-0">
        <div id="cart-table" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl">
          <ag-grid-vue
            style="width: 100%; height: 100%;"
            class="ag-theme-material"
            :gridOptions="gridOptions"
            :columnDefs="columnDefs"
            :rowClassRules="rowClassRules"
            :rowData="rowData"
            rowBuffer=20
            rowSelection="multiple"
            animateRows="true"
            suppressRowClickSelection="true"
            @selection-changed="onSelectionChanged"
            @cell-clicked="onCellClicked"
            @modelUpdated="onModelUpdated"
            @grid-ready="onGridReady"
            @first-data-rendered="onFirstDataRendered"
          ></ag-grid-vue>
        </div>
        <div id="mobileContent"
             class="pr-5"
             v-else>
             <div v-if="rowData && rowData.length > 0">
              <div v-for="(product, i) in rowData" :key="i">
                <v-lazy
                  min-height="50"
                  transition="fade-transition"
                >
                  <v-card class="mb-5" cols="12">
                    <v-carousel
                      :cycle="cycle"
                      v-model="carouselSlider[product.sku]"
                      height="200px"
                      hide-delimiters
                      show-arrows-on-hover
                    >
                      <v-carousel-item
                        v-for="(item,j) in carouselItems(product)"
                        :key="j"
                        :src="item.src"
                        :transition="cycle"
                        :reverse-transition="cycle"
                        @error="onErrorCarouselItem(product)"
                      >
                        <v-container class="centered" fill-height v-if="isMediaMissing(item.src)">
                          <v-row align-center>
                            <v-col cols="12">
                              <h1>
                                <span class="media-message">Photo No Longer Available</span>
                              </h1>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-carousel-item>
                    </v-carousel>
                  <v-card-title>
                    {{product.title}}
                  </v-card-title>
                  <v-card-subtitle>
                  <v-row class="justisty-space-between">
                    <v-col cols="4">{{formatCurrency(product.price)}}</v-col>
                    <v-col cols="4"><b>Quantity:{{product.quantity}}</b></v-col>
                    <v-col cols="4">Total:{{formatCurrency(product.total)}}</v-col>
                  </v-row>
                  <v-row class="px-3">
                    Functional:<a class="theme--light primary--text functionality-button" href="#"
                              @click="onFunctionalityClick(product)">{{product.functionality}}</a>
                  </v-row>
                  </v-card-subtitle>
                  <v-card-actions>
                  <input class="card-selected-box" type="checkbox" :checked="isCardSelected(product)" @click="onSelectCard(product)">
                  <v-spacer></v-spacer>

                  <v-btn
                  icon
                  @click="product.expanded = !product.expanded"
                  >
                  <v-icon>{{ product.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                  </v-card-actions>

                  <v-expand-transition>
                  <div v-show="product.expanded">
                  <v-divider></v-divider>

                  <v-card-text>
                  <v-row>
                  <v-col cols="6">Brand: {{product.brand}}</v-col>
                  <v-col cols="6">Model: {{product.model}}</v-col>
                  </v-row>
                  <v-row>
                  <v-col cols="6">Color: {{product.color}}</v-col>
                  <v-col cols="6">Storage: {{product.storagecapacity}}</v-col>
                  </v-row>
                  <v-row>
                  <v-col cols="6">Carrier: {{product.carrier}}</v-col>
                  <v-col cols="6">Condition: {{product.condition}}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">Lock Status: {{product.lockstatus}}</v-col>
                    <v-col v-if="!!product.batteryhealth" cols="6">Battery: {{product.batteryhealth}}%</v-col>
                  </v-row>
                  <v-row>
                    <v-col v-if="!!product.wholesalenote" cols="12">Notes: {{product.wholesalenote}}</v-col>
                  </v-row>
                  </v-card-text>
                  </div>
                  </v-expand-transition>
                  </v-card>
                </v-lazy>
              </div>
             </div>
             <div class="text-center" v-else>
              No products in you cart.
             </div>
        </div>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import { router } from '@/router';
import formatterMixin from '@/_mixins/formatterMixin';
import { percentageCellRenderer } from '@/utils/formatters';

export default {
  name: 'cart',
  data () {
    return {
      searchFilter: '', // The global search filter
      filterModel: {}, // The ag-grid filter model
      drawer: true,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      columnFields: null,
      rowClassRules: null,
      rowData: null,
      countSelected: 0,
      mediaProduct: null,
      mediaProductRow: null,
      mediaDialog: false,
      rowsToRemove: null,
      functionalityDialog: false,
      cycle: false,
      slider: 0,
      items: [],
      dialog: {
        displayed: false,
        title: '',
        text: ''
      },
      invalidProductsDialog: {
        displayed: false,
        title: '',
        text: '',
        items: []
      },
      directPaymentDialog: {
        displayed: false,
        title: '',
        text: ''
      },
      window: {
        width: 0,
        height: 0
      },
      timer: null,
      skuToSelectedCard: {},
      carouselItemErrors: [],
      carouselSlider: {},
      filteredProductsQuantity: 0
    };
  },
  components: {
    AgGridVue
  },
  beforeMount () {
    this.gridOptions = {};
    this.columnDefs = [
      {
        headerName: 'Item',
        field: 'dbitemnum',
        sortable: true,
        filter: true,
        width: 170,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        // hide: true,
        pinned: 'left'
      },
      {
        headerName: 'Price',
        field: 'price',
        sortable: true,
        filter: true,
        valueFormatter: this.currencyFormatter,
        // cellStyle: {textAlign: "right"},
        width: 120,
        // width: 120,
        pinned: 'left'
      },
      {
        headerName: 'Quantity',
        field: 'quantity',
        sortable: true,
        filter: true,
        // cellStyle: {textAlign: "right"},
        width: 120,
        // width: 120,
        pinned: 'left'
      },
      {
        headerName: 'Total',
        field: 'total',
        sortable: true,
        filter: true,
        valueFormatter: this.currencyFormatter,
        // cellStyle: {textAlign: "right"},
        width: 120,
        pinned: 'left'
      },
      {
        headerName: 'Brand',
        field: 'brand',
        sortable: true,
        filter: true,
        width: 120
        // rowGroup: true
      },
      {
        headerName: 'Model',
        field: 'model',
        sortable: true,
        filter: true,
        width: 140
        // rowGroup: true
      },
      {
        headerName: 'Color',
        field: 'color',
        sortable: true,
        filter: true,
        width: 120
      },
      {
        headerName: 'Storage',
        field: 'storagecapacity',
        sortable: true,
        filter: true,
        width: 120
      },
      {
        headerName: 'Carrier',
        field: 'carrier',
        sortable: true,
        filter: true,
        width: 140
      },
      {
        headerName: 'Condition',
        field: 'condition',
        sortable: true,
        filter: true,
        width: 120
      },
      {
        headerName: 'Lock Status',
        field: 'lockstatus',
        sortable: true,
        filter: true,
        width: 140
      },
      {
        headerName: 'Note',
        field: 'wholesalenote',
        sortable: true,
        filter: true
      },
      {
        headerName: 'Battery Health',
        field: 'batteryhealth',
        cellRenderer: percentageCellRenderer,
        sortable: true,
        filter: true
      },
      {
        headerName: 'Media',
        field: 'sku',
        sortable: false,
        filter: false,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        suppressMenu: true,
        // menuTabs: [],
        width: 106,
        colId: 'media',
        cellRenderer: mediaCellRenderer,
        pinned: 'left'
      },
      {
        headerName: 'Functionality',
        field: 'functionality',
        sortable: true,
        filter: true,
        colId: 'functionality',
        width: 120,
        cellRenderer: functionalityCellRenderer,
        pinned: 'left'
      }
      // {
      //   headerName: "Remove",
      //   field: "sku",
      //   sortable: false,
      //   filter: false,
      //   suppressSizeToFit: true,
      //   suppressAutoSize: true,
      //   suppressMenu: true,
      //   // menuTabs: [],
      //   width: 106,
      //   colId: "remove",
      //   cellRenderer: buttonCellRenderer,
      //   pinned: "left"
      // }
    ];

    this.columnFields = this.columnDefs.map(columnDef => columnDef.field);

    this.rowClassRules = {
      'media-product-row': (params) => {
        if (params.data !== undefined && params.data.isMediaProductRow !== undefined) {
          return params.data.isMediaProductRow;
        }
        return '';
      }
    };
  },
  computed: {
    ...mapState({
      cartProducts: state => state.cart.products,
      // invalidProducts: state => state.cart.invalidProducts,
      cartCount: state => state.cart.count,
      cartTotal: state => state.cart.price,
      mediaBySku: state => state.products.mediaBySku,
      productsBySku: state => state.products.productsBySku,
      directPaymentEnabled: state => state.account.user.directPaymentEnabled,
      payPalEnabled: state => state.account.user.payPalEnabled,
      account: state => state.account,
      globalSettings: state => state.settings.settings
    }),
    ...mapGetters('account', ['userIsReadOnly']),
    directPaymentMaxLimit () {
      if (this.account.user.overrideSettings !== undefined && this.account.user.overrideSettings.directPaymentMaxLimit !== null && this.account.user.overrideSettings.directPaymentMaxLimit > 0) {
        return this.account.user.overrideSettings.directPaymentMaxLimit;
      }
      return this.globalSettings.directPaymentMaxLimit;
    },
    formattedDirectPaymentMaxLimit () {
      return this.formatCurrency(this.directPaymentMaxLimit);
    },
    mediaProductTitle () {
      if (this.mediaProduct !== null) {
        return this.mediaProduct.model + ' (' + this.mediaProduct.dbitemnum + ')';
      }
      return '';
    },
    mediaProductPrice () {
      if (this.mediaProduct !== null) {
        return this.formatCurrency(this.mediaProduct.price);
      }
      return '';
    },
    mediaProductNotes () {
      if (this.mediaProduct !== null) {
        // if (this.mediaProduct.sku.toLowerCase().includes("acc ")) {
        if (this.mediaProduct.sku.toLowerCase().substring(0, 3) === 'acc') {
          return '';
        }
        return this.productsBySku[this.mediaProduct.sku].notes;
      }
      return '';
    },
    checkoutText () {
      if (this.cartCount === 1) {
        return 'Checkout ' + this.formattedCartCount + ' Item: ' + this.formattedCartPrice;
      }
      return 'Checkout ' + this.formattedCartCount + ' Items: ' + this.formattedCartPrice;
    },
    formattedCountSelected () {
      return this.numberWithCommas(this.countSelected);
    },
    formattedCartCount () {
      return this.numberWithCommas(this.cartCount);
    },
    formattedCartPrice () {
      return this.formatCurrency(this.cartTotal);
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize);
    // this.handleResize();
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;

    this.refreshData();
  },
  updated () {

  },
  methods: {
    ...mapActions('cart', ['refreshCartProducts', 'removeAllCartProducts', 'removeProductsFromCart', 'checkout', 'checkoutDirect', 'validate', 'synchronize']),
    ...mapActions('orders', ['getAllOrdersByStatus']),
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    }),
    onFirstDataRendered (params) {
      setTimeout(() => {
        this.autoSizeColumns();
      }, 10);
    },
    onGridReady (params) {
      setTimeout(() => {
        this.autoSizeColumns();
      }, 10);
    },
    autoSizeColumns () {
      if (router.currentRoute.path === '/cart') {
        this.autoSizeAll(false);
        if (this.window.width === 0) {
          this.window.width = window.innerWidth;
        }
        if (this.window.height === 0) {
          this.window.height = window.innerHeight;
        }
        // if (this.window.width !== null && this.window.width >= 1920) {
        //   this.sizeToFit();
        // }
      }
    },
    autoSizeAll (skipHeader) {
      if (this.gridColumnApi) {
        this.gridColumnApi.autoSizeColumns(this.columnFields, skipHeader);
      }
    },
    sizeToFit () {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    handleResize () {
      if (router.currentRoute.path === '/cart') {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;

        setTimeout(() => {
          this.autoSizeColumns();
        }, 100);
      }
    },
    onRemoveFromCartFromMedia () {
      this.dialog.displayed = true;
      this.onCloseMediaDialogHandler();
    },
    onRemoveFromCartFromFunctionality () {
      this.dialog.displayed = true;
      this.onCloseFunctionalityDialogHandler();
    },
    onRemoveFromCart () {
      this.rowsToRemove = this.gridApi ? this.gridApi.getSelectedRows() : Object.values(this.skuToSelectedCard);

      if (this.countSelected === 1) {
        // this.dialog.title = "Remove 1 Product?";
        this.dialog.title = 'Remove Product?';
        this.dialog.text = 'Are you sure you want to remove the selected product from cart?';
      } else {
        // this.dialog.title = "Remove " + this.numberWithCommas(this.countSelected) + " Products?";
        this.dialog.title = 'Remove Products?';
        this.dialog.text = 'Are you sure you want to remove the selected products from cart?';
      }

      this.dialog.displayed = true;

      // this.removeProductsFromCart(this.gridApi.getSelectedRows());
      // this.onClearSelection();
    },
    onClearCart () {
      this.onClearSelection();
      this.removeAllCartProducts();
    },
    onClearSelection () {
      this.countSelected = 0;
      // this.priceSelected = 0;
      if (this.gridApi) {
        this.gridApi.deselectAll();
      } else {
        this.skuToSelectedCard = {};
      }

      this.rowsToRemove = null;
    },
    onSelectionChanged () {
      if (this.gridApi) {
        const selectedRows = this.gridApi.getSelectedRows();
        const countSelected = selectedRows.length;
        this.countSelected = countSelected;
      }
    },
    onCellClicked (event) {
      if (event.column.getColId() === 'remove') {
        this.rowsToRemove = [event.node.data];
        this.dialog.title = 'Remove Product?';
        this.dialog.text = 'Are you sure you want to remove this product from cart?';
        this.dialog.displayed = true;
      // } else if (event.column.getColId() === 'media' && event.data !== undefined) {
      } else if ((event.column.getColId() === 'media' || event.column.getColId() === 'functionality') && event.data !== undefined) {
        // Clear OLD highlighted row
        if (this.mediaProductRow !== null && this.mediaProduct !== null) {
          // if (event.node !== this.mediaProductRow){
          this.mediaProduct.isMediaProductRow = false;
          this.gridApi.redrawRows({ rowNodes: [this.mediaProductRow] });

          this.mediaProductRow = null;
          this.mediaProduct = null;
          // }
        }

        // Set Media Row
        this.mediaProductRow = event.node;
        this.mediaProduct = this.mediaProductRow.data;
        this.mediaProduct.isMediaProductRow = true;
        // this.dialog = true;
        this.gridApi.redrawRows({ rowNodes: [this.mediaProductRow] });

        if (event.column.getColId() === 'media') {
          this.slider = 0;
          this.items = this.mediaBySku[event.value];
          // this.mediaProduct = event.data;
          this.mediaDialog = true;
        } else if (event.column.getColId() === 'functionality') {
          // if (!this.mediaProduct.sku.toLowerCase().includes("acc ")) {
          if (this.mediaProduct.sku.toLowerCase().substring(0, 3) !== 'acc') {
            this.functionalityDialog = true;
          }
        }

        this.rowsToRemove = [event.node.data];
        this.dialog.title = 'Remove Product?';
        this.dialog.text = 'Are you sure you want to remove this product from cart?';
      }
    },
    onModelUpdated (event) {
      this.autoSizeColumns();
    },
    onCheckout () {
      this.validate().then(
        (invalidProducts) => {
          if (invalidProducts.length > 0) {
            this.invalidProductsDialog.title = 'Products in cart out of sync with server!';

            // Build formatted list from response
            this.invalidProductsDialog.items = [];

            for (const invalidProduct of invalidProducts) {
              const item = {};
              // if (invalidProduct.item.sku.toLowerCase().includes("acc ")) {
              if (invalidProduct.item.sku.toLowerCase().substring(0, 3) === 'acc') {
                item.title = invalidProduct.item.genericdescription;
              } else {
                item.title = invalidProduct.item.title;
              }

              switch (invalidProduct.code) {
                case 'PRODUCT_NOT_FOUND':
                case 'OUT_OF_STOCK':
                  item.subtitle = "<span class='invalid-reason'>OUT OF STOCK</span>" + ": Product no longer in stock.  <span class='correct-price'>Will be removed from cart.</span>";
                  break;

                case 'INSUFFICIENT_STOCK':
                  item.subtitle = "<span class='invalid-reason'>INSUFFICIENT STOCK</span>" + ": Quantity in cart updated from <span class='incorrect-price'>" + invalidProduct.userQuantity + "</span> to <span class='correct-price'>" + invalidProduct.serverStock + '</span>.';
                  break;

                case 'PRICE_MISMATCH':
                  item.subtitle = "<span class='invalid-reason'>PRICE CHANGE</span>" + ": Price in cart will be updated from <span class='incorrect-price'>" + this.formatCurrency(invalidProduct.cartPrice) + "</span> to <span class='correct-price'>" + this.formatCurrency(invalidProduct.serverPrice) + '</span>.';
                  break;
              }

              this.invalidProductsDialog.items.push(item);
            }

            this.invalidProductsDialog.displayed = true;
          } else {
            // Create ORDER in PENDING_PAYMENT status and when complete, route to checkout page
            const orderParam = {
              isMobile: this.$vuetify.breakpoint.mobile
            };
            this.checkout(orderParam).then(
              router.push('/checkout')
            );
          }
        }
      );
    },
    onCheckoutDirect () {
      this.validate().then(
        (invalidProducts) => {
          if (invalidProducts.length > 0) {
            this.invalidProductsDialog.title = 'Products in cart out of sync with server!';

            // Build formatted list from response
            this.invalidProductsDialog.items = [];

            for (const invalidProduct of invalidProducts) {
              const item = {};
              item.title = invalidProduct.item.title;

              switch (invalidProduct.code) {
                case 'PRODUCT_NOT_FOUND':
                case 'OUT_OF_STOCK':
                  item.subtitle = "<span class='invalid-reason'>OUT OF STOCK</span>" + ": Product no longer in stock.  <span class='correct-price'>Will be removed from cart.</span>";
                  break;

                case 'INSUFFICIENT_STOCK':
                  item.subtitle = "<span class='invalid-reason'>INSUFFICIENT STOCK</span>" + ": Quantity in cart updated from <span class='incorrect-price'>" + invalidProduct.userQuantity + "</span> to <span class='correct-price'>" + invalidProduct.serverStock + '</span>.';
                  break;

                case 'PRICE_MISMATCH':
                  item.subtitle = "<span class='invalid-reason'>PRICE CHANGE</span>" + ": Price in cart will be updated from <span class='incorrect-price'>" + this.formatCurrency(invalidProduct.cartPrice) + "</span> to <span class='correct-price'>" + this.formatCurrency(invalidProduct.serverPrice) + '</span>.';
                  break;
              }

              this.invalidProductsDialog.items.push(item);
            }

            this.invalidProductsDialog.displayed = true;
          } else {
            // Client-side check: verify direct eligibility first, and if all good THEN checkout direct
            this.getAllOrdersByStatus('pending-direct-payment').then(response => {
              // Process results
              let submitOrder = false;
              if (response.orders.length === 0) {
                submitOrder = true;
              } else {
                // ACTIVE PENDING DIRECT PAYMENT ORDERS EXIST .. CHECK TOTAL!
                // console.log(response.orders);

                // Check if amount exceeds direct limit threshold

                // Sum selected products total and cart total and see if it exceeds threshold of $10,000.00 (or whatever value is set to)
                // Sum 'price' field for all selected rows
                const directPaymentTotal = response.orders.reduce(function (a, b) {
                  // return b["total"] === null ? a : a + b["total"];
                  return b.itemtotal === null ? a : a + b.itemtotal;
                }, 0.0);

                const tempDirectPaymentTotal = directPaymentTotal + this.cartTotal;

                // console.log("PENDING_DIRECT_PAYMENT Current Total: " + this.formatCurrency(directPaymentTotal));
                // console.log("Cart Total: " + this.formatCurrency(this.cartTotal));
                // console.log("Sum: " + this.formatCurrency(tempDirectPaymentTotal));
                // console.log("PENDING_DIRECT_PAYMENT Limit: " + this.formatCurrency(this.directPaymentMaxLimit));
                // submitOrder = true;

                if (tempDirectPaymentTotal > this.directPaymentMaxLimit) {
                  this.directPaymentDialog.text = 'The products in your cart combined with your existing pending direct payment orders would total ' + this.formatCurrency(tempDirectPaymentTotal) + ' exceeding the limit by ' + this.formatCurrency((tempDirectPaymentTotal - this.directPaymentMaxLimit)) + '.';
                  this.directPaymentDialog.displayed = true;
                } else {
                  submitOrder = true;
                }
              }

              if (submitOrder) {
                // Create ORDER in PENDING_PAYMENT status and when complete, route to checkout direct page
                const orderParam = {
                  isMobile: this.$vuetify.breakpoint.mobile
                };
                this.checkoutDirect(orderParam).then((response) => {
                  router.push('/checkout-direct');
                }).catch((err) => {
                  // console.log(err);
                  this.directPaymentDialog.text = err;
                  this.directPaymentDialog.displayed = true;
                });
              }
            });
          }
        }
      );
    },
    onDialogClickHandler (confirm) {
      this.dialog.displayed = false;
      if (confirm) {
        if (this.countSelected === this.cartCount) {
          this.removeAllCartProducts();
        } else {
          this.removeProductsFromCart({ products: this.rowsToRemove });
        }
        this.onClearSelection();
      }
      this.rowsToRemove = null;
    },
    onInvalidProductsDialogClickHandler (confirm) {
      this.invalidProductsDialog.displayed = false;
      if (confirm) {
        this.synchronize();
      }
    },
    onCloseMediaDialogHandler () {
      this.mediaDialog = false;
      this.items = [];
      this.slider = 0;
      // this.mediaProduct = null;
    },
    onCloseFunctionalityDialogHandler () {
      this.functionalityDialog = false;
    },
    onCloseDirectPaymentDialogHandler () {
      this.directPaymentDialog.displayed = false;
    },
    processProducts (products) {
      const rowDataArr = [];
      // orderItems.forEach((orderItem) => {
      products.forEach((obj) => {
        const rowDataObj = {};
        // if (obj["sku"].toLowerCase().includes("acc ")) {
        if (obj.sku.toLowerCase().substring(0, 3) === 'acc') {
          // TODO: Process Accessory product!

          rowDataObj.dbitemnum = obj.category3;

          rowDataObj.brand = obj.brand;
          rowDataObj.model = obj.storefrontmodel;
          rowDataObj.price = obj.wholesaleprice;
          rowDataObj.quantity = parseInt(obj.quantity);
          rowDataObj.total = obj.total;
          rowDataObj.sku = obj.sku;
          rowDataObj.title = obj.title;

          rowDataObj.isMediaProductRow = false; // GOOD
        } else {
          rowDataObj.brand = obj.brand;
          // Use "storefrontmodel" instead of "model"
          rowDataObj.model = obj.storefrontmodel;
          rowDataObj.color = obj.color.toUpperCase();
          rowDataObj.storagecapacity = obj.storagecapacity;
          rowDataObj.carrier = obj.originalcarrier;

          // Use "storefrontcondition" instead of "condition"
          rowDataObj.condition = obj.storefrontcondition;

          // Use "wholesaleprice" value
          rowDataObj.price = obj.wholesaleprice;
          rowDataObj.quantity = parseInt(obj.quantity);
          rowDataObj.total = obj.total;
          rowDataObj.sku = obj.sku;
          rowDataObj.dbitemnum = obj.dbitemnum;
          rowDataObj.title = obj.title;
          rowDataObj.wholesalenote = obj.wholesalenote;
          rowDataObj.batteryhealth = obj.batteryhealth;

          // Lock Status:
          // if [sku] contains " UUU " (with spaces both before and after UUU) then display "GSM Unlocked"
          // if [sku] contains "LLL" then display "Locked"
          // if [sku] contains "UUUVZN" then display "Verizon Unlocked"
          // if [sku] contains "UUUSPT" then display "CDMA/GSM Unlocked" (edited)
          // if [sku] contains "MVNO" then display "MVNO Unlocked"
          // if [sku] contains "FACTUUU" then display "Factory Unlocked"
          let lockStatus = '';
          if (obj.sku.toUpperCase().includes(' UUU ')) {
            lockStatus = 'GSM Unlocked';
          } else if (obj.sku.toUpperCase().includes('LLL')) {
            lockStatus = 'Locked';
          } else if (obj.sku.toUpperCase().includes('UUUVZN')) {
            lockStatus = 'Verizon Unlocked';
          } else if (obj.sku.toUpperCase().includes('UUUSPT')) {
            lockStatus = 'CDMA/GSM Unlocked';
          } else if (obj.sku.toUpperCase().includes('MVNO')) {
            lockStatus = 'MVNO Unlocked';
          } else if (obj.sku.toUpperCase().includes('FACTUUU')) {
            lockStatus = 'Factory Unlocked';
          }
          rowDataObj.lockstatus = lockStatus;

          // Functionality:
          // if [sku] contains "pzn" then display "Issue"
          // if [sku] contains "lwi" then display "Defective"
          // if neither of the above is true, then display "Fully Functional"
          // can we make the  field a link that would open up the [notes] field?
          let functionality = 'Fully Functional';
          if (obj.sku.toLowerCase().includes('pzn')) {
            functionality = 'Issue';
          } else if (obj.sku.toLowerCase().includes('lwi')) {
            functionality = 'Defective';
          }
          rowDataObj.functionality = functionality;
          rowDataObj.isMediaProductRow = false; // GOOD
        }

        rowDataObj.expanded = false;
        this.carouselSlider[rowDataObj.sku] = 0;
        rowDataArr.push(rowDataObj);
      });
      return rowDataArr;
    },
    refreshData () {
      this.loadingOverlay(true);
      this.carouselSlider = {};

      if (this.searchFilter === null || this.searchFilter === '') {
        this.rowData = this.processProducts([...this.cartProducts]);
      } else {
        this.rowData = this.processProducts(this.filterByValue(this.cartProducts, this.searchFilter));
      }

      if (this.gridApi) {
        // SAVE EXISTING FILTER MODEL
        this.filterModel = this.gridApi.getFilterModel();

        // RESTORE / APPLY FILTER MODEL
        if (Object.keys(this.filterModel).length !== 0) {
          setTimeout(() => {
            this.gridApi.setFilterModel(this.filterModel);
            this.gridApi.onFilterChanged();
          }, 100);
        }
      }

      setTimeout(() => {
        if (router.currentRoute.path === '/cart') {
          this.loadingOverlay(false);
        }
      }, 100);

      this.filteredProductsQuantity = 0;
      this.rowData.forEach(product => {
        this.filteredProductsQuantity += product.quantity;
      });
    },
    filterByValue (array, string) {
      // Search all properties for value
      return array.filter(o =>
        Object.keys(o).some(
          (k) => {
            // IGNORE SKU PROP WHEN SEARCHING
            if (k !== 'sku') {
              if (typeof o[k] === 'string' || o[k] instanceof String) {
                return o[k].toLowerCase().includes(string.toLowerCase());
              }
            }
          }
        ));
    },
    onSelectCard (product) {
      const selected = this.skuToSelectedCard[product.sku];
      if (selected) {
        delete this.skuToSelectedCard[product.sku];
      } else {
        this.skuToSelectedCard[product.sku] = product;
      }

      this.countSelected = Object.values(this.skuToSelectedCard).length;
    },
    onSelectAll () {
      this.rowData.forEach(card => {
        this.onSelectCard(card);
      });
    },
    isCardSelected (product) {
      return this.skuToSelectedCard[product.sku] !== undefined;
    },
    onMediaClick (product) {
      this.mediaProduct = product;
      this.rowsToRemove = [product];
      this.slider = 0;
      this.items = this.mediaBySku[product.sku];
      this.mediaDialog = true;
    },
    onFunctionalityClick (product) {
      if (this.mediaProduct.sku.toLowerCase().substring(0, 3) !== 'acc') {
        this.mediaProduct = product;
        this.functionalityDialog = true;
        this.rowsToRemove = [product];
      }
    },
    onErrorCarouselItem (event) {
      this.carouselItemErrors.push(event);
    },
    isMediaMissing (url) {
      return this.carouselItemErrors.includes(url);
    },
    carouselItems (product) {
      if (product !== null && 'sku' in product) {
        return this.mediaBySku[product.sku] || [];
      }
      return [];
    }
  },
  watch: {
    cartProducts (newValue, oldValue) {
      if (this.gridApi) {
        // Clear any previously applied column filters
        this.gridApi.setFilterModel(null);
      }

      this.refreshData();
    },
    searchFilter (newValue) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.refreshData();
      }, 300);
    }
  },
  mixins: [
    formatterMixin
  ]
};

function mediaCellRenderer (params) {
  if (params !== undefined && params.value !== undefined) {
    return '<a class="v-btn v-btn--flat v-btn--small theme--light primary--text media-button" href="#"><i class="material-icons">photo_library</i></a>';
  }
  return '';
};

function functionalityCellRenderer (params) {
  if (params !== undefined && params.value !== undefined) {
    return '<a class="theme--light primary--text functionality-button" href="#">' + params.value + '</a>';
  }
  return '';
}

</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";

#cart-header {
  height: 50px;
}

#cart-table {
  margin-top: 10px;
  height: calc(100vh - 290px);
}

.media-button, .cart-button {
  min-width: 0;
}

#refresh-cart-btn {
  float: right;
}

.functionality-button {
  text-decoration: none;
}

.invalid-reason {
  color: red;
  font-weight: 900;
}

.incorrect-price {
  color: red;
}

.correct-price {
  color: green;
}

.centered {
  text-align: center;
}

.direct-payment-message {
  margin-top: 30px;
}

#remove-from-cart-button button {
  // height: 50px;
  // width: 180px;
}

#checkout-button button {
  height: 50px;
  width: 230px;
}

#checkout-direct-button button {
  height: 50px;
  width: 230px;
}

.clear-cart-selection-button button {
  // height: 50px;
  // width: 60px;
}

.media-product-header {
  padding: 7px;
}

.media-product-price {
  margin-left: 20px;
}

.media-cart-button {
  margin-left: 30px;
}

.media-product-row {
  background-color: #BBDEFB !important;
}

.v-card__text, .v-card__title {
  word-break: normal !important;
}
</style>
