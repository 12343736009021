<template>
  <v-container fluid>
    <v-row v-if="user.isAdmin">
      <v-col cols="12" class="pa-4">
        <v-form ref="form">
          <v-card>
            <v-card-text>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-toolbar color="primary" dark>
                  <v-expansion-panel-header>
                      <v-toolbar-title>Store Credit Widget - Click to Open</v-toolbar-title>
                    </v-expansion-panel-header>
                  </v-toolbar>
                  <v-expansion-panel-content class="pt-4">
                    <v-row>
                    <v-col class="py-0">
                      <span>Select User</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" md="6" class="py-0">
                      <v-autocomplete
                        style="min-width: 100%;"
                        :items="filteredUsernames"
                        filled
                        label="Select User"
                        v-model="selectedUsername"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6" md="6" class="py-0 text-h4" :class="selectedUserBalance < 0 ? 'red--text' : 'green--text'">
                      Current Balance: ${{selectedUserBalance}}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3">
                      <v-select
                          style="min-width: 100%;"
                          class="mt-3"
                          name="action"
                          label="Action"
                          type="text"
                          :items="creditActions"
                          v-model="selectedCreditAction"
                          :disabled="selectedUsername === null"
                          :rules="[nonNullRule]"
                      ></v-select>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                          name="amount"
                          label="Amount"
                          type="number"
                          v-model="amount"
                          prefix="$"
                          :disabled="selectedUsername === null"
                          :rules="[nonNullRule, positiveNumberRule]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                          name="details"
                          label="Details"
                          type="text"
                          v-model="details"
                          :disabled="selectedUsername === null"
                          :rules="[nonNullRule]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-btn class="mt-3" color="primary" @click="handleSubmit()" :disabled="selectedUsername === null">Save Credit</v-btn>
                    </v-col>
                  </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Store Credit Table</v-toolbar-title>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <CreditsTable></CreditsTable>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import CreditsTable from './CreditsTable.vue';

export default {
  name: 'credit',
  components: {
    CreditsTable
  },
  mixins: [
  ],
  data () {
    return {
      searchFilter: '',
      accountStatusFilter: '',
      filteredUsernames: [],
      selectedUsername: null,
      submitted: false,
      selectedCreditAction: null,
      amount: 0,
      details: '',
      balance: null,
      creditActions: [
        { text: 'PAYMENT (+)', value: 'PAYMENT' },
        { text: 'CREDIT (+)', value: 'CREDIT' },
        { text: 'CANCELATION (+)', value: 'CANCELATION' },
        { text: 'REFUND (+)', value: 'REFUND' },
        { text: 'CORRECTION (+)', value: 'CORRECTION+' },
        { text: 'DISCOUNT (+)', value: 'DISCOUNT' },
        { text: 'SHIPPING_CREDIT (+)', value: 'SHIPPING_CREDIT' },
        { text: 'TAX_CREDIT (+)', value: 'TAX_CREDIT' },
        { text: 'FINANCE_FEE (-)', value: 'FINANCE_FEE' },
        { text: 'PAYMENT_FEE (-)', value: 'PAYMENT_FEE' },
        { text: 'ORDER (-)', value: 'ORDER' },
        { text: 'CHARGE (-)', value: 'CHARGE' },
        { text: 'CORRECTION (-)', value: 'CORRECTION-' },
        { text: 'SHIPPING_FEE (-)', value: 'SHIPPING_FEE' },
        { text: 'INSURANCE_FEE (-)', value: 'INSURANCE_FEE' }
      ],
      nonNullRule: value => {
        if (value) return true;

        return 'This field is required.';
      },
      positiveNumberRule: value => {
        if (value > 0) return true;

        return 'This field must be a positive number.';
      }
    };
  },
  created () {

  },
  mounted () {
    if (this.user.isAdmin) {
      this.loadUserData();
      this.getBalances();
      this.selectedUsername = this.$route.params.username || null;
    }

    this.getAllCredits();
  },
  computed: {
    ...mapState('account', ['status', 'user']),
    ...mapState('users', ['usernames', 'users']),
    ...mapState('credits', ['balancesByUsername', 'allCredits']),
    ...mapGetters('users', ['availableStatuses']),
    administratorLabel () {
      return "<strong class='success--text'>Administrator</strong>";
    },
    filteredUsernamesLabel () {
      if ((this.searchFilter === null || this.searchFilter === undefined || this.searchFilter === '') && (this.accountStatusFilter === null || this.accountStatusFilter === undefined || this.accountStatusFilter === '')) {
        return 'All Users';
      }

      if (this.filteredUsernames.length === 1) {
        return 'Filters Applied: 1 Matching User';
      }
      return 'Filters Applied: ' + this.filteredUsernames.length + ' Matching Users';
    },
    selectedUserBalance () {
      if (this.selectedUsername && this.selectedUsername in this.balancesByUsername) {
        return this.balancesByUsername[this.selectedUsername].balance;
      } else {
        return 0;
      }
    }
  },
  methods: {
    // ...mapActions('account', ['register']),
    ...mapActions('users', ['loadUsers']),
    ...mapActions('credits', ['getCreditByUsername', 'createCredit', 'getBalances', 'getAllCredits']),
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    }),
    clearUser () {
      this.selectedUsername = null;
    },
    clearFilters () {
      this.searchFilter = '';
      this.accountStatusFilter = '';
    },
    loadUserData () {
      // alert("Fetching User List from Server...");
      this.clearFilters();
      this.clearUser();
      this.loadUsers();
    },
    getFilteredUsers () {
      // Start with array of all users and apply search filters one at a time
      const filteredUsers = Object.values(this.users);

      return filteredUsers.map(o => o.username);
    },
    refreshData () {
      // GET A SORTED LIST OF USERNAMES THAT MEET CURRENT FILTER CRITERIA
      this.filteredUsernames = this.getFilteredUsers().sort();
    },
    handleSubmit (e) {
      this.submitted = true;
      const valid = this.$refs.form.validate();
      if (valid) {
        const newCredit = {
          action: this.selectedCreditAction,
          amount: this.amount,
          username: this.selectedUsername,
          details: this.details
        };
        this.createCredit(newCredit)
          .then(res => {
            this.successAlert('Successfully created credit.');
            this.amount = 0;
            this.details = '';
            this.selectedCreditAction = null;
            this.$refs.form.resetValidation();
          })
          .catch(err => this.errorAlert('Failed to create credit. ' + err));
      }
    },
    filterByAccountStatus (array, accountStatus) {
      // Search accountStatus property for value
      return array.filter(o => o.accountStatus.toLowerCase() === accountStatus.toLowerCase());
    },
    filterByValue (array, string) {
      // Search all properties for value
      return array.filter(o =>
        Object.keys(o).some(
          (k) => {
            if (typeof o[k] === 'string' || o[k] instanceof String) {
              return o[k].toLowerCase().includes(string.toLowerCase());
            }
          }
        ));
    }
  },
  watch: {
    users (to, from) {
      this.refreshData();
    },
    searchFilter (newValue) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.refreshData();
      }, 300);
    },
    accountStatusFilter (newValue) {
      this.refreshData();
    },
    async selectedUsername (to, from) {
      this.getCreditByUsername(this.selectedUsername);
    },
    '$route.path': {
      handler: function (to, from) {
        if (to === '/credit') {
          this.getAllCredits();
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
